// Dependencies
import React from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import SectionCopy from "../components/SectionCopy";
import SectionFeatures from "../components/SectionFeatures";
import SectionGallery from "../components/SectionGallery";
import SectionGrid from "../components/SectionGrid";
import SectionMedia from "../components/SectionMedia";
import SectionPlaylist from "../components/SectionPlaylist";
import SectionPartners from "../components/SectionPartners";

const PageSections = ({ sections, ...props }) => {

  return (
    sections.map((section, index) => {
			const sectionProps = {
				...section,
				...props,
				key: `${section.id}-${index}`
			}

      return section.__typename === 'ContentfulSectionCopy' ? (
        <SectionCopy {...sectionProps} />
      ) : section.__typename === 'ContentfulSectionFeatures' ? (
        <SectionFeatures {...sectionProps} />
      ) : section.__typename === 'ContentfulSectionMedia' ? (
        <SectionMedia {...sectionProps} />
      ) : section.__typename === 'ContentfulSectionGrid' ? (
        <SectionGrid {...sectionProps} />
      ) : section.__typename === 'ContentfulSectionGallery' ? (
        <SectionGallery {...sectionProps} />
      ) : section.__typename === 'ContentfulSectionPlaylist' ? (
        <SectionPlaylist {...sectionProps} />
      ) : section.__typename === 'ContentfulSectionPartners' ? (
        <SectionPartners {...sectionProps} />
      ) : (
        <div className="debug" key={sectionProps.key}>
          TODO
        </div>
      )
    })
  )
}

export default PageSections
