// Dependencies
import React from "react";
import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Artwork from "../components/Artwork";
import Section from "../components/Section";
import RichText from "../components/RichText";
import { VideoPlayerButton } from "../components/VideoPlayer";

const SectionVideo = ({ coverArtwork, titles, vimeoUrl, youTubeId }) => {

  return (
    <div className="prel">
      {coverArtwork && <Artwork {...coverArtwork} />}
      <div className="video-btn-overlay">
        <div className="pabs fs flex-center">
          <VideoPlayerButton titles={titles} video={{ vimeoUrl, youTubeId }} />
        </div>
      </div>
    </div>
  )
}

const SectionMedia = ({ title, artwork, video, image, hires, caption }) => {

  return (
    <Section title={title}>
      <div className="grid">
        <div className="col-center">
          {artwork && <Artwork {...artwork} />}
          {video && <SectionVideo {...video} />}
          {hires && <img src={hires.file.url} alt="MSCTY" />}
        </div>
        {caption && (
          <div className="col-caption sideways p3 has-links">
            <RichText content={caption} />
          </div>
        )}
      </div>
			<div className="copy-section grid has-links outer">
				<div className="p2 right">
					{video && video.titles && <RichText content={video.titles} />}
				</div>
			</div>
			
    </Section>
  )
}

export default SectionMedia

export const ContentfulSectionMediaFragment = graphql`
  fragment ContentfulSectionMediaFragment on ContentfulSectionMedia {
		__typename
    id
    title
    artwork {
      ...ContentfulArtworkFragment
    }
    image {
      description
      file {
        url
        contentType
        details {
          image {
            width
            height
          }
        }
      }
			large: gatsbyImageData(
				layout: FULL_WIDTH
			)
    }
    video {
      ...ContentfulVideoFragment
    }
    caption {
      raw
    }
    hires: image {
      file {
        url
        contentType
        details {
          image {
            width
            height
          }
        }
      }
    }
  }
`
