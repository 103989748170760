// Dependencies
import React from "react";
import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Section from "../components/Section";
import RichText from "../components/RichText";

const SectionCopy = ({ title, body, headings, aside, locale }) => {

  return (
    <Section title={title} locale={locale}>
      <div className="copy-section grid has-links">
        {headings && (
          <div className="p1 left">
            <RichText content={headings} locale={locale} />
          </div>
        )}
        <div className="p2 right">
          <RichText content={body} locale={locale} />
        </div>
        {aside && (
          <aside className="p3 left">
            <RichText content={aside} locale={locale} />
          </aside>
        )}
      </div>
    </Section>
  )
}

export default SectionCopy

export const ContentfulSectionCopyFragment = graphql`
  fragment ContentfulSectionCopyFragment on ContentfulSectionCopy {
		__typename
    id
    title
    headings {
			raw
			references {
				... on ContentfulTrack {
					...ContentfulTrackFragment
				}
			}
    }
    body {
			raw
			references {
				... on ContentfulPage {
					__typename
					contentful_id
					title
					slug
					category {
						slug
					}
				}
				... on ContentfulListenPage {
					__typename
					contentful_id
					title
					slug
				}
				... on ContentfulAsset {
					__typename
					contentful_id
					file {
						url
					}
					large: gatsbyImageData(
						layout: FULL_WIDTH
					)
				}
				... on ContentfulEmbed {
					__typename
					contentful_id
					height
					width
					maxHeight
					embedCode {
						embedCode
					}
				}
				... on ContentfulTrack {
					...ContentfulTrackFragment
				}
			}
    }
    aside {
			raw
			references {
				... on ContentfulAsset {
					__typename
					contentful_id
					file {
						url
					}
					large: gatsbyImageData(
						layout: FULL_WIDTH
					)
				}
				... on ContentfulArtwork {
					...ContentfulArtworkFragment
				}
			}
    }
  }
`
