// Dependencies
import React, { useRef } from "react";
import { graphql } from "gatsby";
import { useDrag } from "react-use-gesture";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import Img from "../components/Img";
import Image from "./Image";
import RichText from "../components/RichText";
import { PlayTrackButton } from "../components/MusicPlayer";
import useMicrocopy from "../hooks/useMicrocopy";

function useDragToScroll() {
  // Ref for scrolling element
  const ref = useRef();
  // UI
  let startPoint = 0;
  const bind = useDrag((event) => {
    const { first, movement: [mx] } = event;

    if (first) {
      startPoint = ref.current.scrollLeft;
    }

    ref.current.scrollLeft = startPoint - mx;
  });
  return [ref, bind]
}

const SectionGallery = ({ id, title, slides, locale }) => {

  const [ref, bind] = useDragToScroll();

	const { clickdragswipe } = useMicrocopy(locale)

  return (
    <div>
      <div className="gallery__title padx2">
        {/* <h3>{title} {`[${slides.length} images]`}</h3> */}
        <p><strong className="upcase">{title}</strong> {`[${slides.length} images]`}</p>
        <p className="p3">{`← ${clickdragswipe} →`}</p>
      </div>

      <div className="gallery" {...bind()} ref={ref}>
        <div className="gallery__wrapper">
          {slides.map((slide, index) => (
            <div className="gallery__item" key={`${id}-slide-${index}`}>
              <div className="gallery__item__content">
                <div className="media">
                  {/* <Img {...slide.image} /> */}
									<Image image={slide.image.large} proHeight alt="" />
                </div>
                <div className="caption p3">
                  <RichText content={slide.caption} />
                </div>
              </div>
              {slide.track && (
                <div className="track outerx2">
                  <PlayTrackButton {...slide.track} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>


    </div>

  )
}

export default SectionGallery

export const ContentfulSectionGalleryFragment = graphql`
  fragment ContentfulSectionGalleryFragment on ContentfulSectionGallery {
		__typename
    id
    title
    slides {
      image {
        description
        file {
          url
          contentType
        }
				large: gatsbyImageData(
					layout: FULL_WIDTH
				)
      }
      caption {
				raw
      }
      track {
        ...ContentfulTrackFragment
      }
      # video {
      #   ...ContentfulVideoFragment
      # }
    }
  }
`
