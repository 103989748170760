// Dependencies
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
import useMicrocopy from "../hooks/useMicrocopy";
// Components
import Artwork from "../components/Artwork";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SectionFeatures from "../components/SectionFeatures";
import RichText from "../components/RichText";
import PageSections from "../components/PageSections";

const PageTemplate = ({ data, pageContext: { isHomepage, locale } }) => {

  const { 
		metaTitle, 
		metaDescription, 
		metaKeywords, 
		title, 
		pageTitle,
		titles, 
		shareImage, 
		cover, 
		headline, 
		sections, 
		issue, 
		colours, 
		credits 
	} = data.contentfulPage;

	const { related } = useMicrocopy(locale)

	const meta = !isHomepage ? {
		title: metaTitle || title,
		description: metaDescription,
		keywords: metaKeywords,
		shareImage
	} : {}

  return (
    <Layout
			{...meta}
			locale={locale}
      headerTitles={!isHomepage ? titles : null}
      colours={colours || (issue && issue.colours)}
      isPage={issue ? true : false}
    >
      {cover && (
        <Section title={pageTitle || title} locale={locale}>
          <div className="page-hero" key={`page-cover`}>
            <div className="page-hero__image">
              {cover && <Artwork {...cover} context="page-cover" locale={locale} />}
            </div>
            <div className={`page-hero__text ${cover.configuration.backOrientation} p1`}>
              <RichText content={headline} locale={locale} />
            </div>
          </div>
        </Section>
      )}

      {sections && <PageSections sections={sections} locale={locale} />}

      {credits && (
        <div className="page-credits p3">
          <div>
            <RichText content={credits} locale={locale} />
          </div>
        </div>
      )}

      {issue && issue.page && (
        <SectionFeatures 
					title={related} 
					headline={issue.headline} 
					colours={issue.colours} 
					links={issue.page} 
					locale={locale} 
				/>
      )}
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTemplate

export const PageTemplatePageQuery = graphql`
  query PageTemplateQuery($slug: String!, $locale: String!) {
    contentfulPage(
			slug: { eq: $slug }, 
			node_locale: { eq: $locale }
		) {
			## META
			metaTitle
			metaDescription {
				text: metaDescription
			}
			metaKeywords
			## Content
      title
      slug
			pageTitle
      shareImage {
        file {
          url
        }
      }
      titles {
        raw
      }
      cover {
        ...ContentfulArtworkFragment
      }
      thumbnail {
        ...ContentfulArtworkFragment
      }
      headline {
        raw
				references {
          ... on ContentfulTrack {
						...ContentfulTrackFragment
          }
        }
      }
      colours {
        primary
        secondary
      }
      issue {
        colours {
          primary
          secondary
        }
        headline {
          raw
        }
        page {
          slug
          category {
            slug
          }
          titles {
            raw
          }
          caption {
            raw
          }
          cover {
            ...ContentfulArtworkFragment
          }
					thumbnail {
						...ContentfulArtworkFragment
					}
        }
      }
      credits {
        raw
      }
      ## Sections
      sections {
        ### Section: Copy
        ... on ContentfulSectionCopy {
          ...ContentfulSectionCopyFragment
        }
        ### Section: Features
        ... on ContentfulSectionFeatures {
          ...ContentfulSectionFeaturesFragment
        }
        ### Section: Media
        ... on ContentfulSectionMedia {
          ...ContentfulSectionMediaFragment
        }
        ### Section: Grid
        ... on ContentfulSectionGrid {
          ...ContentfulSectionGridFragment
        }
        ### Section: Gallery
        ... on ContentfulSectionGallery {
          ...ContentfulSectionGalleryFragment
        }
        ### Section: Playlist
        ... on ContentfulSectionPlaylist {
          ...ContentfulSectionPlaylistFragment
        }
				### Section: Partners
				... on ContentfulSectionPartners {
					...ContentfulSectionPartnersFragment
				}
      }
    }
  }
`
