// Dependencies
import React from "react";
import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import GridTrack from "../components/GridTrack";
import Section from "../components/Section";
import Playlist from "../components/Playlist";

const PlaylistGrid = ({ playlist, locale }) => (
	<div className="grid board-section">
		{playlist && playlist.tracks.map((track, index) => {
			// const { artwork, copy } = track;
			return (
				<GridTrack track={track} playlist={playlist} locale={locale} key={`track-${index}`} />
			)
		})}
	</div>
)

const PlaylistEmbed = ({ playlist, locale }) => {

	return (
		<div>
			<Playlist className="embedded" {...playlist} locale={locale} />
		</div>
	)
}

const SectionPlaylist = ({ layout, title, ...props }) => {

	const isEmbed = layout && layout === 'embedded'

  return (
    <Section title={title}>
			{isEmbed ? <PlaylistEmbed {...props} /> : <PlaylistGrid {...props} />}
    </Section>
  )
}

export default SectionPlaylist

export const ContentfulSectionPlaylistFragment = graphql`
  fragment ContentfulSectionPlaylistFragment on ContentfulSectionPlaylist {
		__typename
    title
    playlist {
      ...ContentfulPlaylistFragment
    }
		layout
  }
`
