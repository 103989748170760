// Dependencies
import React from "react";
import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Artwork from "./Artwork";
import Image from "./Image";
// import Img from "./Img";
import GradientMap from "./GradientMap";
import GridTrack from "./GridTrack";
import RichText from "./RichText";
import Section from "./Section";
import { VideoPlayerButton } from "./VideoPlayer";
import Link from "./LocaleLink";

const GridItemArtwork = ({
  id,
  colours,
  imageBack,
  configuration
}) => {

  // Content
  const from = colours ? colours.primary : '#000000';
  const to = colours ? colours.secondary : '#FFFFFF';

  // Style
  const { backSkew, backFilter } = configuration || {};

  return (
    <div className="media">
      <GradientMap id={`filter-${id}-back`} from={backFilter !== 'duotone-inverted' ? from : to} to={backFilter !== 'duotone-inverted' ? to : from} />
      <div className={`mask ${backSkew}`}>
        {imageBack && (
          <div className="filter" style={{
            filter: (backFilter === 'duotone') || (backFilter === 'duotone-inverted') ? `url(#filter-${id}-back)` : (backFilter === 'b-w') ? `grayscale(1)` : `none`
          }}>
            <Image image={imageBack.large} />
          </div>
        )}
      </div>
    </div>
  )
}

const GridItemVideo = ({ coverArtwork, titles, vimeoUrl, youTubeId }) => {

  return (
    <div className="prel">
      {coverArtwork && <GridItemArtwork {...coverArtwork} />}
      <div className="video-btn-overlay">
        <VideoPlayerButton titles={titles} video={{ vimeoUrl, youTubeId }} />
      </div>
    </div>
  )
}

const GridItemContents = ({ video, image, copy, pageLink, locale, ...props }) => {

	const artwork = props.artwork || (pageLink && pageLink.cover) || (pageLink && pageLink.thumbnail)

  return (
    <div>
      {pageLink ? (
				<Link to={pageLink.category ? `/${pageLink.category.slug}/${pageLink.slug}` : `/${pageLink.slug}`} locale={locale}>
					<Artwork {...artwork} locale={locale} />
				</Link>
			) : video ? (
        <GridItemVideo {...video} locale={locale} />
      ) : artwork ? (
        <Artwork {...artwork} locale={locale} />
      ) : image ? (
        <div className="prel">
          {/* <Img {...image} /> */}
					<Image image={image.large} alt="" />
        </div>
      ) : null}

      <div className="p2 copy outer has-links">
        <RichText content={copy} locale={locale} />
      </div>
    </div>
  )
}

const GridItem = (props) => {

  return props.link ? (
    <Link to={props.link} locale={props.locale} className="grid-item">
      <GridItemContents {...props} />
    </Link>
  ) : (
    <div className="grid-item">
      <GridItemContents {...props} />
    </div>
  )
}

const SectionGrid = ({ title, items, locale }) => {

  return (
    <Section title={title} locale={locale}>
      <div className="grid board-section">
        {items.length < 3 && <div className="grid-item" />}
        {items.map((item, index) => {
					const key = `${item.id}-${index}`
          return item.sys.contentType.sys.id === 'page' ? (
            <GridItem 
							artwork={item.cover || item.thumbnail} 
							copy={item.titles} link={item.category ? `/${item.category.slug}/${item.slug}` : `/${item.slug}`} 
							locale={locale}
							key={key} 
						/>
          ) : item.track ? (
            <GridTrack track={item.track} copy={item.copy} locale={locale} key={key} />
          ) : (
						<GridItem {...item} locale={locale} key={key} />
					)
        })}
      </div>
    </Section>
  )
}

export default SectionGrid

export const ContentfulSectionGridFragment = graphql`
  fragment ContentfulSectionGridFragment on ContentfulSectionGrid {
		__typename
    id
    title
    items {
      ... on ContentfulSectionGridItem {
        sys {
          contentType {
            sys {
              id
            }
          }
        }
        image {
          description
          file {
            url
            contentType
          }
					large: gatsbyImageData(
						layout: FULL_WIDTH
					)
        }
        artwork {
          ...ContentfulArtworkFragment
        }
        video {
          ...ContentfulVideoFragment
        }
				track {
					...ContentfulTrackFragment
				}
				pageLink {
					title
					slug
					category {
						slug
					}
					titles {
						raw
					}
					cover {
						...ContentfulArtworkFragment
					}
					thumbnail {
						...ContentfulArtworkFragment
					}
					headline {
						raw
					}
					colours {
						primary
						secondary
					}
				}
        copy {
          raw
					references {
						... on ContentfulTrack {
							...ContentfulTrackFragment
						}
						... on ContentfulPage {
							__typename
							contentful_id
							title
							slug
							category {
								slug
							}
						}
						... on ContentfulPlaylist {
							__typename
							contentful_id
							slug
						}
					}
        }
      }
      ... on ContentfulPage {
        sys {
          contentType {
            sys {
              id
            }
          }
        }
        title
        slug
        category {
          slug
        }
        titles {
          raw
        }
        cover {
          ...ContentfulArtworkFragment
        }
        thumbnail {
          ...ContentfulArtworkFragment
        }
        headline {
          raw
        }
        colours {
          primary
          secondary
        }
      }
    }
  }
`
