// Dependencies
import React from "react";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Artwork from "../components/Artwork";
import RichText from "../components/RichText";
import { PlayTrackButton } from "../components/MusicPlayer";

const GridTrack = ({ track, playlist, copy }) => (
	<div className="grid-item">
		<div>
			{track.artwork ? (
				<div className="artwork--square">
					<Artwork {...track.artwork} />
				</div>
			) : (
				<div className="placeholder" />
			)}
			<div className="outer">
				<PlayTrackButton {...track} playlist={playlist} />
			</div>
			<div className="p2 copy outer has-links">
				<RichText content={copy || track.copy} />
			</div>
		</div>
	</div>
)

// copy test

export default GridTrack