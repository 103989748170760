// Dependencies
import React from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import Image from "../components/Image";

const Section = ({ className = "", title, colours, children }) => {

  return (
    <section className={`section ${className}`} style={colours ? {
      backgroundColor: colours.secondary,
      color: colours.primary
    } : null}>
      <div className="section__title">
        <h3>{title || "\u00A0"}</h3>
      </div>
      <div className="section__content">
        {children}
      </div>
    </section>
  )
}

export default Section
