// Dependencies
import React from "react";
import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Artwork from "./Artwork";
import Section from "./Section";
import RichText from "./RichText";
import Link from "./LocaleLink";
import { JP } from "../constants/locales";

export const FeatureLink = ({ slug, category, cover, thumbnail, titles, caption, captionJp, locale }) => {

	const artwork = cover || thumbnail;

  return (
    <Link to={category ? `/${category.slug}/${slug}` : `/${slug}`} locale={locale} className="feature">
      <div className="thumbnail">
        <div className="thumbnail__content">
					{locale === JP && captionJp ? (
						<div className="caption p3" lang="jp">
							<RichText content={captionJp} locale={locale} />
						</div>
					) : (
						<div className="caption p3" lang="en">
							<RichText content={caption} locale={locale} />
						</div>
					)}
          <div className="media">
            <Artwork {...artwork} locale={locale} />
          </div>
        </div>
      </div>
      <div className="titles">
        <RichText content={titles} locale={locale} />
      </div>
    </Link>
  )
}

const SectionFeatures = ({ title, headline, colours, links, locale }) => {

  return (
    <Section title={title} colours={colours} locale={locale}>
      {headline && (
        <div className="grid mbx4">
          <aside className="p4 features-headline">
            <RichText content={headline} locale={locale} />
          </aside>
        </div>
      )}

      <div className="collection grid">
        {links && links.map((entry, index) => (
          <FeatureLink {...entry} locale={locale} key={`${entry.id}-${index}`} />
        ))}
      </div>
    </Section>
  )
}

export default SectionFeatures

export const ContentfulSectionFeaturesFragment = graphql`
  fragment ContentfulSectionFeaturesFragment on ContentfulSectionFeatures {
		__typename
    id
    title
    links {
      # ContentfulPage
      slug
      category {
        slug
      }
      titles {
				raw
      }
      caption {
        raw
      }
			captionJp {
        raw
      }
      cover {
        ...ContentfulArtworkFragment
      }
			thumbnail {
        ...ContentfulArtworkFragment
      }
    }
  }
`
