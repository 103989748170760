// Dependencies
import React from "react";
import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import Img from "./Img";
// import Image from "./Image";
import Section from "./Section";
import RichText from "./RichText";

const PartnerLogos = ({ logos }) => {

	return (
		<div className="partner-logos">
			{logos.map((asset, index) => {
				return (
					<div className="logo" key={`${asset.id}${index}`}>
						<div className="img-pad">
							<img src={asset.file.url} />
						</div>
					</div>
				)
			})}
		</div>
	)
}

const SectionPartners = ({ title, aside, logos }) => {

  return (
    <Section className="section__partners" title={title}>
      <div className="copy-section grid has-links">
        <div className="p2 right">
					{logos && <PartnerLogos logos={logos} />}
        </div>
        <aside className="p3 left">
					{aside && <RichText content={aside} />}
				</aside>
      </div>
    </Section>
  )
}

export default SectionPartners

export const ContentfulSectionPartnersFragment = graphql`
  fragment ContentfulSectionPartnersFragment on ContentfulSectionPartners {
		__typename
    id
    title
    aside {
			raw
			references {
				... on ContentfulAsset {
					__typename
					contentful_id
					id
					file {
						url
					}
					large: gatsbyImageData(
						layout: CONSTRAINED
						width: 900
					)
				}
			}
    }
		logos {
			id
			file {
				url
			}
		}
  }
`
